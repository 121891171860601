<template>
  <div>
    <NavigationBar
      titel="Dienststellung bearbeiten"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="createItem"
      >
        <duty-position-editor
          v-model="dutyPosition"
          mode="edit"
        ></duty-position-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { UPDATE_DUTY_POSITION } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import DutyPositionEditor from "@/components/member-management/duty-positions/DutyPositionEditor.vue";

export default {
  name: "member-management-duty-position-edit",
  components: {
    NavigationBar,
    DutyPositionEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.updateItem,
        },
      ],

      helpers: {
        formIsValid: false,
      },
      dutyPosition: null,
    };
  },
  computed: {},
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("dutyPositions")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.dutyPosition = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    validate() {
      this.$refs.form.validate();
    },
    updateItem() {
      this.validate();

      if (this.helpers.formIsValid) {
        var res = confirm(
          "Bist Du sicher, dass Du diesen Dienststellung aktualisieren möchtest?\n\nEs werden die Dantesätze aller Mitglieder angepasst, die diesen Dienstgrad aktuell inne haben?"
        );
        if (res == true) {
          this.$store.dispatch(`${MEMBER_MANAGEMENT}${UPDATE_DUTY_POSITION}`, {
            organizationId: this.$route.params.organizationId,
            itemId: this.$route.params.itemId,

            title: this.dutyPosition.title,
            shortTitle: this.dutyPosition.shortTitle,
            sortKey: this.dutyPosition.sortKey,
            isActive: this.dutyPosition.isActive,
          });
        }
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
  },
};
</script>
