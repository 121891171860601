<template>
  <div>
    <NavigationBar
      titel="Neue Dienststellung anlegen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="createItem"
      >
        <duty-position-editor
          v-model="dutyPosition"
          mode="new"
        ></duty-position-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { CREATE_DUTY_POSITION } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import DutyPositionEditor from "@/components/member-management/duty-positions/DutyPositionEditor.vue";

export default {
  name: "member-management-duty-positions-new",
  components: {
    NavigationBar,
    DutyPositionEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],

      helpers: {
        formIsValid: false,
      },
      dutyPosition: {
        title: "",
        shortTitle: "",
        sortKey: null,
        isActive: true,
      },
    };
  },

  methods: {
    createItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${MEMBER_MANAGEMENT}${CREATE_DUTY_POSITION}`, {
          organizationId: this.$route.params.organizationId,
          title: this.dutyPosition.title,
          shortTitle: this.dutyPosition.shortTitle,
          sortKey: this.dutyPosition.sortKey,
          isActive: this.dutyPosition.isActive,
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
