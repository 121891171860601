export const importableDutyPositions = [
  {
    title: "Stellvertretender Jugendfeuerwehrwart",
    shortTitle: "JFW-V",
    isActive: true,
    sortKey: 1,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.jfw-v",
  },
  {
    title: "Stellvertretende Jugendfeuerwehrwartin",
    shortTitle: "JFW-V",
    isActive: true,
    sortKey: 1,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.jfw-vin",
  },

  {
    title: "Jugendfeuerwehrwart",
    shortTitle: "JFW",
    isActive: true,
    sortKey: 2,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.jfw",
  },
  {
    title: "Jugendfeuerwehrwartin",
    shortTitle: "JFW",
    isActive: true,
    sortKey: 2,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.jfwin",
  },

  {
    title: "Stellvertretender Wehrleiter",
    shortTitle: "WeL-V",
    isActive: true,
    sortKey: 3,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.wel-v",
  },
  {
    title: "Stellvertretende Wehrleiterin",
    shortTitle: "WeL-V",
    isActive: true,
    sortKey: 3,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.wel-vin",
  },

  {
    title: "Wehrleiter",
    shortTitle: "WeL",
    isActive: true,
    sortKey: 4,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.wel",
  },
  {
    title: "Wehrleiterin",
    shortTitle: "WeL",
    isActive: true,
    sortKey: 4,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.welin",
  },
];
