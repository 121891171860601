<template>
  <div>
    <section>
      <TippCard
        hintID="Mitgliederverwaltung_Dienststellungen_1_V1"
        text="Ämter können als Dienststellungen den Personaldaten von Mitgliedern zugewiesen werden."
      >
      </TippCard>
      <v-snackbar v-model="helpers.snackbar.model" timeout="5000"
        >{{ helpers.snackbar.text }}.</v-snackbar
      >
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-account-tie"
                title="Dienststellungen"
                :actions="actions"
              ></Subheader>
              <div class="mt-7">
                <v-data-table
                  :headers="dutyPositionHeaders"
                  :items="dutyPositions"
                  sort-by="sortKey"
                  :sort-desc="false"
                  class="elevation-2"
                  no-data-text="Keine Dienststellungen vorhanden"
                  @click:row="openDutyPosition"
                >
                  <template v-slot:item.badgeUrl="{ item }">
                    <v-img
                      v-if="item.badgeUrl.size70x70"
                      :src="item.badgeUrl.size70x70"
                      contain
                      height="30px"
                    ></v-img>
                    <v-chip v-else label small
                      ><v-icon small>mdi-help</v-icon></v-chip
                    >
                  </template>
                  <template v-slot:item.isActive="{ item }">
                    <v-simple-checkbox
                      v-model="item.isActive"
                      color="primary"
                      disabled
                    ></v-simple-checkbox>
                  </template>
                </v-data-table></div></v-col></v-row></v-container
      ></template>
    </section>
    <custom-dialog
      v-if="helpers.dataImport.dialogModel"
      @close="toggleImportDialog()"
      title="Dienststellungen importieren"
      text="Importierte Dienststellungen stehen nach dem Import sofort zur Verfügung."
      width="m"
      :errorMessage="helpers.dataImport.errorMessage"
      :btnAction="startDataImport"
      :btnDisabled="helpers.dataImport.selectedImportTemplates.length === 0"
      :loading="helpers.dataImport.loading"
      btnText="Auswahl importieren"
    >
      <template v-slot:content>
        <v-form
          v-model="helpers.dataImport.formIsValid"
          @submit.prevent="startDataImport()"
        >
          <v-card outlined class="mt-2">
            <v-data-table
              v-model="helpers.dataImport.selectedImportTemplates"
              :items="helpers.dataImport.importTemplates"
              :headers="helpers.dataImport.tableHeaders"
              :search="helpers.dataImport.search"
              item-key="title"
              group-by="state"
              show-select
              :sort-by="helpers.dataImport.sortSelector"
              :sort-desc="false"
              checkbox-color="primary"
              disable-pagination
              hide-default-footer
              mobile-breakpoint="0"
              fixed-header
              height="35vh"
              class="mt-1"
              no-results-text="Keine Ergebnisse für Suchbegriff"
            >
              <template v-slot:top>
                <v-card flat>
                  <v-card-subtitle>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="helpers.dataImport.search"
                          label="Nach Dienststellung suchen"
                          prepend-inner-icon="mdi-magnify"
                          dense
                          hide-details="auto"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <span class="mr-4">Sortieren nach</span>
                        <v-chip-group
                          v-model="helpers.dataImport.sortSelector"
                          mandatory
                          active-class="primary--text"
                        >
                          <v-chip
                            label
                            value="sortKey"
                            class="transparent-background font-weight-medium mr-2 mb-3"
                            ><v-icon left small>mdi-sort-numeric-variant</v-icon
                            >Sortierschlüssel
                          </v-chip>
                          <v-chip
                            label
                            value="title"
                            class="transparent-background font-weight-medium mb-3"
                            ><v-icon left small
                              >mdi-sort-alphabetical-variant</v-icon
                            >Alphabetisch</v-chip
                          >
                        </v-chip-group>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
                <v-divider></v-divider>
              </template>
              <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                <th
                  :colspan="helpers.dataImport.tableHeaders.length++"
                  class="group-header-th"
                >
                  <v-icon @click="toggle" class="mr-2"
                    >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                  </v-icon>
                  <span>
                    {{ items[0].state }}
                  </span>
                </th>
              </template>
              <template v-slot:item.badgeUrl="{ item }">
                <v-img
                  v-if="item.badgeUrl.size400x400"
                  :src="item.badgeUrl.size400x400"
                  contain
                  height="50px"
                  class="mb-2"
                ></v-img>
                <v-chip v-else label small
                  ><v-icon small>mdi-help</v-icon></v-chip
                >
              </template>
              <template v-slot:[`item.title`]="{ item }">
                <div class="d-flex flex-column">
                  <span
                    class="d-block font-weight-semibold text--primary text-truncate"
                    >{{ item.title }}</span
                  >
                  <small class="grey--text">{{ item.shortTitle }}</small>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-form>
      </template>
    </custom-dialog>
  </div>
</template>

<script>
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import { importableDutyPositions } from "@/data/import/importable-dutyPositions.js";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import {
  GET_DUTY_POSITIONS,
  CREATE_DUTY_POSITIONS,
} from "@/store/action-types.js";
import { MEMBER_MANAGEMENT_CONFIG_DUTY_POSITIONS_create } from "@/data/permission-types.js";

export default {
  name: "member-management-config-dutyPositions",
  components: {
    TippCard,
    Subheader,
    CustomDialog,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus",
          permission: `${MEMBER_MANAGEMENT_CONFIG_DUTY_POSITIONS_create}`,
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "member-management-config-duty-positions-new",
            });
          },
        },
        {
          title: "Importieren",
          icon: "mdi-import",
          permission: `${MEMBER_MANAGEMENT_CONFIG_DUTY_POSITIONS_create}`,
          actionStyle: "textBtn",
          function: this.toggleImportDialog,
        },
      ],
      dutyPositionHeaders: [
        {
          text: "Abzeichen",
          value: "badgeUrl",
          sortable: false,
          align: "center",
        },
        {
          text: "Kurzbezeichnung",
          value: "shortTitle",
          sortable: false,
        },
        { text: "Bezeichnung", value: "title", sortable: false },
        {
          text: "Sortierschlüssel",
          value: "sortKey",
          sortable: true,
        },
        {
          text: "Aktiv",
          value: "isActive",
          sortable: true,
        },
      ],
      helpers: {
        dataImport: {
          dialogModel: false,
          loading: false,
          formIsValid: false,
          importTemplates: importableDutyPositions,
          sortSelector: "priority",
          errorMessage: "",
          search: "",
          selectedImportTemplates: [],
          tableHeaders: [
            { text: "Abzeichen", value: "badgeUrl", align: "start" },
            {
              text: "Titel",
              value: "title",
              align: "start",
            },
            { text: "Sortierschlüssel", value: "sortKey" },
          ],
        },
        snackbar: { model: false, text: "" },
      },
    };
  },
  computed: {
    dutyPositions() {
      return this.$store.state.memberManagement.dutyPositions;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_DUTY_POSITIONS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    openDutyPosition(item) {
      this.$router.push({
        name: "member-management-config-duty-positions-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.meta.id,
        },
      });
    },
    toggleImportDialog() {
      this.helpers.dataImport.dialogModel =
        !this.helpers.dataImport.dialogModel;
    },
    startDataImport() {
      if (this.helpers.dataImport.selectedImportTemplates.length > 0) {
        this.helpers.dataImport.loading = true;
        this.$store
          .dispatch(`${MEMBER_MANAGEMENT}${CREATE_DUTY_POSITIONS}`, {
            organizationId: this.$route.params.organizationId,

            dutyPositions: this.helpers.dataImport.selectedImportTemplates,
          })
          .then(() => {
            return this.initialize();
          })
          .then(() => {
            this.toggleImportDialog();
            this.helpers.dataImport.loading = false;
            this.helpers.dataImport.selectedImportTemplates = [];
            this.helpers.dataImport.search = "";
            this.helpers.snackbar.model = true;
            this.helpers.snackbar.text = "Import erfolgreich";
          })
          .catch((error) => {
            this.helpers.dataImport.loading = false;
            console.log(error);
            this.helpers.dataImport.errorMessage = error.message;
          });
      } else {
        alert(
          "Wähle mindestens eine Dienststellung aus, um den Import duchrzuführen."
        );
      }
    },
  },
};
</script>
