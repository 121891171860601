<template>
  <div>
    <v-card link @click="routeDetails(rank.meta.id)">
      <v-card-title v-if="rank.badgeUrl.size125x125" class="mb-0 pb-0">
        <v-card flat class="ma-0 pa-0">
          <v-img
            v-if="rank.badgeUrl.size125x125"
            contain
            :src="rank.badgeUrl.size125x125"
            color="grey"
            height="80px"
          ></v-img>
        </v-card>
      </v-card-title>

      <v-card-title class="subheading font-weight-bold">
        {{ rank.title }}
      </v-card-title>
      <v-card-subtitle>
        {{ rank.shortTitle }} –
        <span>
          <v-icon small>mdi-dumbbell</v-icon>
          {{ rank.sortKey }}
        </span>
        <v-chip
          v-if="!rank.isActive"
          label
          small
          class="font-weight-medium red--text text--darken-4 ml-2"
          color="red lighten-4"
          ><v-icon small left>mdi-archive</v-icon>Nicht aktiv</v-chip
        >
      </v-card-subtitle>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "rank-detail-card",
  components: {},
  props: {
    rank: {
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    routeDetails(item) {
      this.$router.push({
        name: "member-management-config-ranks-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item,
        },
      });
    },
  },
};
</script>
